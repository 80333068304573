const overloading = {
  breakpointNames: ['sm', 'md', 'lg', 'xl', 'xxl'],
  breakpoints: [576, 768, 992, 1200, 1600]
}

const variables = {
  familyBase: '"proxima-nova",sans-serif',
  familyHeader: '"proxima-nova",serif',

  primaryColor: '#0a74ba',
  fontColor: '#454949',
  bgColor: '#ebebeb',
  blucColor:'#0a74ba',
  darkBlueColor:'#262261',
  ...overloading
}

export default variables;